<template>
  <div class="my-6">
    <v-container>
      <div class="display-2 mb-6 text-center">CHECKOUT</div>
      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="font-weight-bold">View Product</span>
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-4 grey lighten-4">
            <v-row justify="center">
              <v-col
                cols="12"
                :sm="carts.length === 1 ? 12 : 6"
                v-for="(cart, i) in carts"
                :key="i"
              >
                <v-card outlined>
                  <v-row no-gutters>
                    <v-col cols="2">
                      <v-img
                        :src="cart.image[0]"
                        aspect-ratio="1"
                        height="100%"
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="10"
                      class="grey lighten-5"
                      align-self="stretch"
                    >
                      <div class="pa-4">
                        <div class="font-weight bold title mb-2 text-truncate">
                          {{ cart.name }}
                        </div>
                        <div class="d-flex mb-1">
                          <span class="caption">Quantity</span>
                          <v-spacer></v-spacer>{{ cart.qty }}
                        </div>
                        <div class="d-flex mb-1">
                          <span class="caption">Subtotal</span>
                          <v-spacer></v-spacer>${{ calculatePrice(cart, i) }}
                        </div>
                        <div class="d-flex flex-wrap mb-1">
                          <span class="caption">Shipping</span>
                          <v-spacer></v-spacer>
                          <span class="caption">Calculate at next step</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="d-flex justify-end title font-weight-black mt-4">
        Total
        <div class="title primary--text ml-2 font-weight-black">
          ${{ calculateTotal() }}
        </div>
      </div>

      <v-divider class="my-6"></v-divider>

      <v-form
        v-model="valid"
        id="formCheckout"
        ref="formCheckout"
        lazy-validation
      >
        <v-sheet class="mb-6">
          <div class="title font-weight-bold mb-6">Contact Information</div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.first_name"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.last_name"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="email"
                outlined
                readonly
                dense
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.phone"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="Phone Number"
                maxlength="15"
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="mb-6">
          <div class="title font-weight-bold mb-6">Shipping Address</div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.country"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="Country / Region"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.company"
                outlined
                dense
                label="Company (Optional)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.address"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="Full Address"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.address_type"
                outlined
                dense
                label="Apartement, Suite, etc (Optional)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.city"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="City"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.province"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                label="Province"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.postal_code"
                :rules="[(v) => !!v || errMsg]"
                outlined
                dense
                maxlength="10"
                label="Postal Code"
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>

      <v-dialog v-model="dialogTerm" fullscreen persistent>
        <v-card>
          <v-card-title>
            Term & Condition
            <v-spacer></v-spacer>
            <v-icon @click="showTerm()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container id="term-and-condition" class="py-6">
              <div v-html="terms"></div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="d-flex align-center mb-4">
        <v-checkbox v-model="checkTerm" hide-details class="mt-n1">
          <template slot="label"> I Agree to the </template>
        </v-checkbox>
        <a class="ml-2" @click="showTerm()">terms & conditions</a>
      </div>

      <v-btn
        :disabled="!checkTerm"
        color="primary"
        block
        large
        @click="checkoutHandler()"
        >Continue to shipping</v-btn
      >
    </v-container>
  </div>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      dialogTerm: false,
      terms: "",
      carts: [],
      valid: false,
      errMsg: "Please fill out this field",
      form: {
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        address_type: "",
        country: "",
        city: "",
        province: "",
        postal_code: "",
        phone: "",
        remember: true,
      },
      checkTerm: false,
      email: "",
    };
  },
  mounted() {
    if (this.$store.getters.g_transaction.transaction_detail.length === 0) {
      this.$router.push("/cart");
    }
    this.getCart();
    this.email = store.getters.g_user.email;
    // this.$refs.formCheckout.validate();
    this.terms = this.$store.getters.g_profile.term_and_condition;
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    showTerm() {
      this.dialogTerm = !this.dialogTerm;
    },
    async getCart() {
      const DATA = this.$store.getters.g_cart;
      this.carts = DATA;
    },
    calculateTotal() {
      let total = 0;
      this.carts.map((r) => {
        total = total + r.total;
      });
      return total;
    },
    calculatePrice(item, idx) {
      const total = Number(item.price) * Number(item.qty);
      if (idx !== undefined) this.carts[idx].total = total;
      return total;
    },
    async checkoutHandler() {
      const address = {
        type: "address",
        address: this.form,
      };

      if (this.$refs.formCheckout.validate()) {
        store.commit("SET_CHECKOUT", address);
        const res = await store.dispatch("checkoutHandler");
        if (res.code) {
          store.commit("SET_CART", []);
          const q = { ...this.$store.getters.g_transaction };
          q.transaction.message = "";
          q.transaction_detail = [];

          store.commit("SET_CHECKOUT", q);
          setTimeout(() => {
            this.$router.push("/my-transaction");
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss">
#formCheckout {
  .row {
    .col,
    .col-12 {
      padding: 0px 12px;
    }
  }
}
</style>
